import React from "react";
import { isMobile, isIOS } from "react-device-detect";

const App = () => {
  React.useEffect(() => {
    if (isMobile) {
      if (isIOS) {
        window.location.href = "https://apps.apple.com/us/app/pizza-hut-thailand/id1409887273";
      } else {
        window.location.href = "https://play.google.com/store/apps/details?id=com.pizzahut.thailand";
      }
    } else {
      window.location.href = "https://www.pizzahut.co.th";
    }
  }, []);

  return (
    <>
    </>
  );
};

export default App;